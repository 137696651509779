import stopPropagation from '../system/stopPropagation.js';
let formData = new FormData();

global.initModal = function () {
    $('.modal-contact-submit').on('click', function (_e) { // Quand on cherche a soumettre le formulaire de contact
        stopPropagation(_e);
        var target = $(_e.delegateTarget);
        contactSubmit($(target).attr('data-id-modal'));
    })
}

function contactSubmit(_id_modal) {
    // On définit les variables qui vont nous être utiles
    const form = document.querySelector('#modal-contact-form' + _id_modal);
    const paragraph = document.querySelector('#res-ajax' + _id_modal);
    let cas = $(form).find('#cas').val();
    const data = new FormData(form);
    let countError = 0;

    // Vérification JS , il y a t'il des champs non corrects ?  
    data.forEach((value, key) => {
        countError += document.querySelector('.modal.show').querySelector('[name=' + key + ']').validity.valid ? 0 : 1;

        // Mise à jour du formulaire final ( après toutes les étapes )
        formData.set(key, value);
    })

    // Si nous n'avons pas d'erreur
    if (countError === 0) {

        $(".spinner-modal").removeClass('hidden');

        // On renvoie vers le controlleur en ajax
        if (cas == "adresse") {
            sendAdress(form, formData);
        } else {
            sendAjax(form, formData);
        }
    } else { // La vérification JS a détéctée des champs non corrects

        $("#res-ajaxFooter, #res-ajaxFAQ").removeClass('hidden');
    }

}

let sendAjax = (form, data) => {
    $.ajax({
        type: "POST",
        cache: false,
        url: form.getAttribute('action'),
        enctype: 'multipart/form-data',
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
            // On affiche le formulaire renvoyer
            const formHTML = document.createElement("div");
            formHTML.innerHTML = res.form;
            document.querySelector('.modal.show').querySelector('.modal-body').innerHTML = formHTML.querySelector('.modal-body').innerHTML;
            $('body').append(res.dataLayer);
            
            /* Si on est dans le cas adresse */
            if (res.cas == 'adresse') {

                document.getElementById('close-modal-contact').style.display = 'none';

                /* On appel le dataLayer */
                dataLayer.push({
                    'event' : 'account',
                    'accountInfo': {
                        'statut':  "success",
                        'info_code': "200",
                        'locale': res.id_locale,
                        'location': 'Formulaire de contact'
                    }
                });

                setTypeaheadAddress();

                document.querySelector('.modal.show').querySelector('.modal-footer').innerHTML = formHTML.querySelector('.modal-footer').innerHTML;

                $('#modal-contact-submitFooter').on('click', function (e) {
                    e.preventDefault();
                    contactSubmit(res.idModal);
                });

                initModal();

                /* Si on est en état confirmation */
            } else if (res.cas == 'confirmation' || res.cas == 'error') {
                document.querySelector('.modal.show').querySelector('.modal-footer').innerHTML = formHTML.querySelector('.modal-footer').innerHTML;
            }

            $(".spinner-modal").addClass('hidden');
        },
        error: function () {

            /* On appel le dataLayer */
            dataLayer.push({
                'event' : 'account',
                'accountInfo': {
                    'statut':  "error_system",
                    'info_code': "500",
                    // 'locale': res.id_locale,
                    'location': 'Formulaire de contact'
                },
                'eventTimeout': 2000
            });
            
            // Erreur
            $("#res-ajaxFooter, #res-ajaxFAQ").removeClass('hidden');
            $(".spinner-modal").addClass('hidden');
        }
    })
}

let sendAdress = (form, data) => {

    var __country = $(form).find('#pays :selected').attr('data-country-code') || 'FRA';
    var __language = $(form).find('#pays :selected').attr('data-iso') || 'FR';
    var __pays = $(form).find('#pays :selected').val() || 65;

    $.ajax({
        type: "POST",
        cache: false,
        url: form.getAttribute('action'),
        dataType: "json",
        data: {
            countryCode: __country,
            languageCode: __language.toLowerCase(),
            action: 'add-address',
            libelleAdresse: "Adresse principale",
            raisonSocialeAdresse: "",
            nomAdresse: $(form).find('input[name=nom]').val().toUpperCase(),
            prenomAdresse: $(form).find('input[name=prenom]').val().toUpperCase(),
            adresse: $(form).find('input[name=adresse]').val(),
            complement: "",
            codePostal: $(form).find('input[name=CP]').val(),
            ville: $(form).find('input[name=ville]').val(),
            lieu_dit: "",
            etat: $(form).find('input[name=etat]').val(),
            pays: __pays,
            idAddress: null
        },

        // L'adresse c'est bien ajouté dans se cas on la défini comme étant l'adresse favorite
        success: function (ret) {

            $.ajax({
                type: "POST",
                cache: false,
                url: '/client-contact/setFavouriteAdresse',
                data: {
                    id_adresse: ret.id_adresse,
                    id_contact: ret.id_contact
                },

                success: function (res) {
                    if (res.cas == 'confirmation' || res.cas == 'error') {

                        document.getElementById('close-modal-contact').style.display = 'inherit';

                        // On affiche le formulaire renvoyer
                        const formHTML = document.createElement("div");
                        formHTML.innerHTML = res.form;
                        document.querySelector('.modal.show').querySelector('.modal-body').innerHTML = formHTML.querySelector('.modal-body').innerHTML;

                        document.querySelector('.modal.show').querySelector('.modal-footer').innerHTML = formHTML.querySelector('.modal-footer').innerHTML;

                        $(".spinner-modal").addClass('hidden');

                    }
                }

            });

        },
        error: function () {
            $(".spinner-modal").addClass('hidden');
        }
    })
}

// Pour la modal d'adresse

function setTypeaheadAddress() {

    fieldsModalContact();

    $('.typeahead_cp_ville, .typeahead_adresse').on('input', function () {

        if ($(".typeahead_adresse").val() != '' && $(".typeahead_adresse").val().length > 3 && $('.typeahead_cp_ville').val() != '') {

            $('.modal-footer').find('.btn-adresse-modal').prop("disabled", false);

        }
    });
}

function fieldsModalContact() {

    $('#modal-contact-formFooter').find('select').selectpicker();
    $('#modal-contact-formFooter').find('[auto-format]').each(function (_idx, _e) {
        switch ($(_e).attr('auto-format')) {
            case 'capitalize':
                $(_e).on('keyup', function (e) {
                    $(this).val($(this).val().toUpperCase());
                });
                break;
            case 'capitalize-word':
                $(_e).on('keyup', function (e) {
                    $(this).val($(this).val().toLowerCase().capitalize_word());
                });
                break;
            case 'lowerize':
                $(_e).on('keyup', function (e) {
                    $(this).val($(this).val().toLowerCase());
                });
                break;
        }
    });
}
