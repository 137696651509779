/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './scss/app.scss';
import './bootstrap';
import '@popperjs/core';
const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

require('bootstrap-select');
require('bootstrap-3-typeahead');
global.bootbox = require('bootbox');
require('jquery-debounce-throttle');

require('intersection-observer');
import './js/modules/notificationData.js';

// Import JS Files
import './js/modules/swiper.js';
import 'bootstrap';
import './js/form.js';
import './js/load_modal_login.js';
import './js/validationFormulaire.js';
import './js/validFormMdp.js';
import './js/notifications.js';

import './js/modules/capEncy.js';
import './js/modules/findClasses.js';

// customer parts
import './js/customer/customerTelephone.js';
import './js/customer/customerInformations.js';
import './js/customer/customerProInformations.js';
import './js/customer/customerBasket.js';
import './js/customer/customerContact.js';

// seo
import './js/seo/clickGtagAndLocation.js';
import './js/seo/sendViewPage.js';
import './js/seo/seoText.js';
// quotation
import './js/quotation/quotation.js';

// contact modal
import './js/contact/modal.js';

//DataLayer
import './js/dataLayer/setEmplacementNbItem.js';

import stopPropagation from './js/system/stopPropagation.js';
import './js/system/buttonOnLoad.js';
import loadModalContact from './js/load_modal_contact.js';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import googleTagManager from '@analytics/google-tag-manager'
import doNotTrack from 'analytics-plugin-do-not-track'

import 'lazysizes';

import { auto } from '@popperjs/core';
import { setEmplacementNbItem } from './js/dataLayer/setEmplacementNbItem.js';

global.$ = $;

$(function () {

    $('[data-bs-toggle="popover"]').popover();
    $('.selectpicker').selectpicker();

    $('.selectpicker').on('loaded.bs.select', function (e) {
        document.querySelectorAll('.selectpicker_number').forEach(e => {
            e.querySelectorAll('input').forEach(i => {
                i.addEventListener('keyup', function () {
                    this.value = (this.value).replaceAll(',', '.');
                    $(this).trigger('input');
                });
            });
        });
    });

    var _plugins = [];
    if (typeof gtagID != 'undefined') {
        _plugins.push(
            googleTagManager({
                containerId: gtagID
            })
        );
    }

    if (_plugins.length == 0) {
        _plugins.push(doNotTrack());
    }

    global.analytics = Analytics({
        app: '123roulement',
        plugins: _plugins
    });

    if (!$('html').hasClass('mobile') || $('.navbar-toggler').css('display') == 'none') {
        // quand on survole le menu
        $('header .nav-item').on('mouseenter', function (_e) {
            $(_e.currentTarget).addClass('show');
            $(_e.currentTarget).find(".dropdown-menu").addClass('show');
            $(_e.currentTarget).find(".dropdown-menu-end").css({'left': auto, 'right': 0});
        })
        // quand on quite le menu
        $('header .nav-item').on('mouseleave', function (_e) {
            $('header .nav-item.show').removeClass('show');
            $(_e.currentTarget).find('.dropdown-menu.show').removeClass('show');
        });
    }


    var innerSelect = null;
    if ($('html').hasClass('tablette')) {
        $('.keep-open').on({
            "hide.bs.dropdown": function (e) {
                if (window.innerSelect != null) {
                    return false;
                }
                return (typeof (e.clickEvent) != 'object');
            }
        });
    }

    $('.selectpicker').on({
        "hide.bs.select": function (e) {
            window.innerSelect = null;
        }
    });

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    });

    // remove toast after hidden
    $('.toast').on('hidden.bs.toast', function (_e) {
        $(_e).remove();
    });


    /* Bouton pour le Seo de la page gamme et rubrique */
    if (document.querySelector('[data-seo-texte-cache]')) {
        toggleSeoText('[data-seo-texte-cache]');
    }
    
    setEmplacementNbItem();
});
